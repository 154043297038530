:root {
  .contact.container {
    --grid-columns: 1;
  }
  .row.contact-information {
    --grid-columns: 2;
  }
}

@include lg {
  :root {
    .contact.container {
      --grid-columns: 2;
    }
  }
}

.contact.container {
  margin-bottom: 6em;
  .row {
    &:first-child {
      gap: $gap-medium;
    }
    .col {
      .contact-line {
        margin: 3em 0;
        background: #ebedf4;
      }
      ul {
        li {
          display: flex;
          align-items: stretch;
          gap: 0.75em;
          &:not(:last-child) {
            margin-bottom: 0.6em;
          }
          .contact-link {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
@include md {
  .contact.container {
    .row {
      &:first-child {
        flex-wrap: nowrap;
        gap: 9em;
      }
    }
  }
}