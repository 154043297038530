.about-introduction {
  &.subpages-heading {
    h1 {
      text-align: left;
      margin: unset;
      max-width: unset;
    }
  }
  .container {
    .row {
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 2.5em;
      hr {
        display: none;
        width: 300px;
        height: 2px;
        background: #000;
        border: none;
        outline: none;
      }
    }
  }
}

@include lg {
  .about-introduction {
    .container {
      .row {
        margin-top: 2em;
        hr {
          display: unset;
        }
      }
    }
  }
}

.competitive-advantage {
  .container {
    .row {
      margin-top: 2.5em;
      .col {
        svg {
          width: 30px;
          &.check {
            width: 25px;
          }
        }
        p {
          position: relative;
          color: #3f434a;
          margin-left: 1em;
          &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: -1em;
            width: 2px;
            height: 80%;
            background: #747679;
          }
        }
      }
    }
  }
}

.about-reference {
  h2 {
    text-align: center;
  }
  .row {
    margin-top: 2.5em;
    .col {
      padding: 1em;
      border: 2px solid #dcdee8;
      border-radius: 10px;
      height: 185px;
      overflow-y: auto;
      &:last-child {
        background: #3868c7;
        border: unset;
        padding: 0;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          width: 100%;
          text-align: center;
          color: #fff;
          padding: 1em;
        }
      }
    }
  }
}
