.services-links {
  margin-top: 4em;
  .services-row {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    overflow-x: auto;
    &:last-of-type {
      margin-top: 1.2em;
    }
    .services-card {
      border-radius: 50px;
      gap: 0.5em;
      align-items: center;
      h5 {
        margin: 0;
      }
    }
  }
}

@include xl {
  .services-links {
    .services-row {
      justify-content: center;
    }  
  }
}

.reason-to-choose-container {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: #f7f9fc;
    z-index: -1;
  }
  .reason-to-choose {
    margin-top: 3em;
  }
}

.services-offer {
  background: $clr-secondary-dark;
  border-radius: 30px;
  &-container {
    margin: 6em 0 3em;
    .services-offer-service {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .9em .3em;
      border-top: 1px solid #ABABAE;
      transition: 0.25s;
      &:hover {
        padding: .9em 1em;
        h4 {
          color: #000;
        }
        .icon {
          background: rgb(208, 210, 217);
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transform: scaleY(0);
        width: 100%;
        height: 100%;
        background: #ebedf0;
        transition: transform 0.25s ease-out;
        z-index: 1;
      }
      &.hover-from-top {
        border-color: #ebedf0;
        &::before {
          transform-origin: bottom right;
        }
        &:hover {
          &::before {
            transform: scaleY(1);
            transform-origin: top right;
          }
        }
      }
      &.hover-from-bottom {
        border-color: #ebedf0;
        &::before {
          transform-origin: top right;
        }
        &:hover {
          &::before {
            transform: scaleY(1);
            transform-origin: bottom right;
          }
        }
      }
      h4,
      p,
      .icon {
        z-index: 2;
        transition: 0.25s;
      }
      .icon {
        display: grid;
        place-items: center;
        background: #EBEDF0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }
}
@include md {
  :root {
    .services-offer.container {
      --constainer-padding: 6em;
    }
  }
}

.mini-about {
  text-align: center;
  p { 
    max-width: 400px;
    margin: 0 auto 2.4em;
  }
}