.blog-post-wrapper {
  .container {
    .back-to-all {
      display: flex;
      gap: 0.7em;
      font-size: 1.3em;
      color: $clr-accent-light;
      width: min-content;
      white-space: nowrap;
      svg {
        transform: translateY(2px) rotate(-180deg);
        height: 30px;
        path {
          stroke: $clr-accent-light;
        }
      }
    }
    .blog-post-info {
      display: inline-block;
      padding: 0.2em 0.7em;
      margin: 1.6em 0 2.5em;
      border: 1px solid #bdc2ca;
      border-radius: 5px;
    }
    .introduction-image {
      width: 100%;
      max-height: 700px;
      margin-top: 4.5em;
      border-radius: 10px;
    }
  }
}

:root {
  .blog-post-content {
    .container {
      --grid-columns: 1;
    }
  }
}

.blog-post-content {
  margin-top: 0;
  .container {
    padding-top: 1em;
    .row {
      .content_toc-outer.col {
        position: sticky;
        top: 10em;
        max-height: 75vh;
        display: none;
        .content_toc-wrapper {
          position: sticky;
          top: 10em;
          max-height: 75vh;
          font-size: 0.9em;
          padding-left: 0.75rem;
          overflow-y: auto;
          padding-bottom: 2rem;
          .content_toc {
            display: flex;
            flex-direction: column;
            h5 {
              color: #2667e8;
            }
            a {
              border-radius: 0.5rem;
              margin-bottom: 0.125rem;
              margin-left: -0.75rem;
              padding: 0.375rem 0.75rem;
              font-size: 0.875rem;
              font-weight: 700;
              color: #3f434a;
              transition:
                color 0.2s,
                background-color 0.2s;
              &.toc-h3 {
                margin-left: 0;
                margin-bottom: 0.25rem;
              }
              &.is-active,
              &:hover {
                color: #000;
                background: #f7f9fc;
              }
            }
          }
        }
        .content_toc-gradient-overlay {
          z-index: 1;
          height: 3rem;
          background-image: linear-gradient(rgba(255, 255, 255, 0), #fff);
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: 0%;
        }
      }

      .blog-post.col {
        // Heading
        h2 {
          margin: 1.6em 0 0.4em;
        }
        h3 {
          margin: 1em 0 0.2em;
        }

        // Paragraph
        h2 + p,
        h3 + p {
          margin-top: 0;
        }

        p {
          margin-top: 1em;
        }

        // Image
        img {
          margin-top: 1.8em;
          height: 500px;
          border-radius: 10px;
        }

        // List
        ul,
        ol {
          margin: 1em 0 0 2em;
        }
        ul {
          list-style: unset;
        }

        // Link
        a {
          text-decoration: underline;
        }

        // Blockquote
        blockquote {
          border-left: 0.15rem solid $clr-accent-light;
          padding: 0.5em 0 0.5em 1em;
          margin: 1.5em 0;
          font-size: 1.1em;
        }

        // Code
        code {
          background: #e8e8e8;
          border-radius: 5px;
          padding: 0.2em 0.5em;
        }

        // Global
        :first-child {
          margin-top: 0;
        }
        :not(h2, h3) {
          color: #3f434a; //#475467
        }
      }
    }
  }
}

@include breakpoint(54.3125rem) {
  // 869px
  .blog-post-content {
    .container {
      .row {
        .content_toc-outer.col {
          display: unset;
          width: 20%;
        }
        .blog-post.col {
          width: 75%;
        }
      }
    }
  }
}

.next-blog-posts {
  h2 {
    margin-bottom: 0.5em;
  }
  .primary-cta {
    margin-top: 8em;
  }
}

/*
// Obsah článku
    const heading = document.querySelectorAll(".blog-post-content h2");
    if (heading) {
      setPostContent(heading);
    }

*/
