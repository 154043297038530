@import "./breakpoints";
@import "../abstract/variables";

// Components
@import "./navbar";
@import "./buttons";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-padding-top: 9em;
}

body {
  font-family: $ff-text;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background: #fff; // #F5F5F5;
  overflow-x: hidden;
  &.frozen {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
label {
  margin: 0 0 0.5rem;
  line-height: 1.2;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  &.light {
    color: #ebedf0;
  }
}

p {
  line-height: 1.5;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  &.light {
    color: #ababae;
  }
  &.dark {
    color: #3f434a;
  }
  &.not-complete-text {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /*&::after {
      content: "";
      position: absolute;
      bottom: 2px;
      right: 0;
      width: 60px;
      height: 16px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 59%);
    }*/
  }
}

h1,
h2,
h3 {
  font-family: $ff-heading;
}

h4,
h5 {
  font-family: $ff-text;
}

h1 {
  font-size: clamp(2.5rem, 3vw, 4rem);
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 1.1;
}

h2 {
  font-size: clamp(2.2rem, 2.5vw, 3.125rem);
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: clamp(1.75rem, 1.6500000000000001vw, 1.8rem);
}

h4 {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  font-weight: 500;
}

h5,
label {
  font-size: 1.1rem;
  font-weight: 400;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

hr {
  outline: none;
  background: #ebedf4;
}

.disable-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

::selection {
  color: $clr-white;
  background: $clr-accent-light;
}

.injected-svg {
  width: 20px;
}

input,
textarea {
  border: none;
  background: none;
  outline: none;
}

.slide {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $clr-secondary-dark;
  z-index: 2000;
  overflow: hidden;
  pointer-events: none;
  will-change: opacity, visibility;
  .injected-svg {
    position: absolute;
    bottom: 3%;
    right: 3%;
    width: 60px;
  }
}

//pages
@import "../pages/home";
@import "../pages/about";
@import "../pages/services";
@import "../pages/contact";
@import "../pages/blog";
@import "../pages/blog-post";

@import "./footer";

@import "./styleguide";

@import "./normalize";
