.button-fill-light,
.button-fill-dark,
.button-fill-shade,
.button-outline-light {
  padding: .4em 1.8em;
  border-radius: 5px;
  color: $clr-white;
  font-size: clamp(1rem, 3vw, 1.1rem);
  font-family: $ff-text;
  font-weight: 500;
  transition: .2s; 
  white-space: nowrap;
}

.button-fill-light {
  border: 2px solid $clr-accent-light;
  background: $clr-accent-light;
}

.button-outline-light {
  border: 2px solid $clr-accent-light;
  &:hover {
    background: $clr-accent-light;
  }
}

.button-fill-shade {
  display: inline-flex;
  gap: 1em;
  color: #000;
  background: #F7F9FC;
  /*svg {
    transition: .2s;
    path { 
      transition: 3s;
    }
  }*/

  &:hover {
    background: #f0f3f5;
    /*svg {
      path {
        d: path("M20.5 1L25.5 5.5M25.5 5.5L20.5 10M25.5 5.5H0");
        d: "M20.5 1L25.5 5.5M25.5 5.5L20.5 10M25.5 5.5H0";
      }
    }*/
  }
}

.button-fill-dark {
  background: $clr-accent-dark;
  &:hover{
    background: #174fc0;
  }
}

.wannabe-link {
  font-family: $ff-heading;
  font-weight: 600;
  svg {
    width: 11px;
    height: 11px;
  }
}