$clr-white: #ffffff;
$clr-primary: "";
$clr-secondary-light: "";
$clr-secondary-dark: #1d1e24;
$clr-accent-light: #2667e8;
$clr-accent-dark: #1554d0;
$clr-accent-dark-dark: "";

// Titillium Web - 600, 700,
// Lato - 300, 400
$ff-heading: "Titillium Web", sans-serif;
$ff-text: "Lato", sans-serif;

$gap-medium: max(2.25em, 6.25vh);

:root {
  --gap: clamp(1em, 4vw, 2.5em);

  // home page
  .home-services.container {
    --gap: 1.4em;
  }

  // about page
  .competitive-advantage {
    --gap: 2em;
  }
  .about-reference-contect {
    --gap: .4em;
  }

  // blog
  .next-blog-posts.container,
  .blog-posts.container {
    --gap: 1.6em;
  }
}

@include xs {
  :root {
    --constainer-padding: 1.18em;
    --grid-columns: 1;
  }
}

@include sm {
  :root {
    --constainer-padding: 2.8em;
  }
}

@include md {
  :root {
    --constainer-padding: 4em;
    --grid-columns: 2;
  }
}

@include lg {
  :root {
    --constainer-padding: 2em;
    --grid-columns: 3;
  }
}

/*@include xl {
  :root {
    --constainer-padding: .5em;
  }
}*/