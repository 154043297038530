.home-introduction {
  position: relative;
  min-height: 700px;
  margin-top: unset;
  padding-top: 8em;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 40%;
    height: 100%;
    background: #d3d8e0;
    z-index: -1;
  }
  .container {
    .row {
      .col {
        max-width: 700px;
        p {
          margin: 1.5em 0 1.3em;
        }
      }
    }
  }
  .introduction-image {
    position: absolute;
    bottom: 0;
    left: 65%;
    transform: translateX(-50%);
    display: none;
    flex-direction: column;
    align-items: flex-end;
  }
}

@include md {
  :root {
    .home-introduction {
      --grid-columns: 1;
    }
  }
}
@include breakpoint(1174px) {
  .home-introduction {
    min-height: 730px;
    &::after {
      display: unset;
    }
    .container {
      .row {
        .col {
          max-width: 600px;
        }
      }
    }
    .introduction-image {
      display: flex;
    }
  }
}

.target-group {
  margin-top: 0;
}
@include md {
  :root {
    .target-group {
      --grid-columns: 2;
    }
  }
}

.home-about {
  .container {
    .row {
      justify-content: center;
      align-items: center;
      .col {
        &:nth-child(1) {
          max-width: 400px;
          display: none;
        }
      }
    }
  }
}

@include xs {
  :root {
    .home-about {
      --grid-columns: 1;
    }
  }
}

@include breakpoint(833px) {
  .home-about {
    .container {
      .row {
        .col {
          &:nth-child(1) {
            display: unset;
          }
        }
      }
    }
  }

  :root {
    .home-about {
      --grid-columns: 2;
    }
  }
}

.blog-section {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 70%;
    background: #f7f9fc;
    border-radius: 0 20px 20px 0;
    z-index: -1;
  }
  .container {
    .row {
      .col {
        &.sticky {
          margin-bottom: 3.6em;
          div {
            position: sticky;
            top: 10em;
            h2 {
              margin-bottom: 1em;
            }
          }
        }
        &:nth-child(2) {
          ul {
            li {
              &:not(:last-child) {
                border-bottom: 2px solid #929395;
                padding-bottom: 2em;
                margin-bottom: 2em;
              }
              .row {
                .col {
                  img {
                    width: 100%;
                    max-height: 300px;
                    object-fit: cover;
                    border-radius: 10px;
                  }
                }
              }
            }
            hr {
              margin: 3em 0;
            }
          }
        }
      }
    }
  }
}

@include md {
  :root {
    .blog-section {
      .container {
        --grid-columns: 1;
      }
    }
  }
}
@include breakpoint(950px) {
  :root {
    .blog-section {
      .container {
        --grid-columns: 2;
      }
    }
  }
  .blog-section {
    .container {
      .row {
        .col {
          &.sticky {
            margin-bottom: unset;
          }
        }
      }
    }
  }
}
