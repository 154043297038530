.blog-posts {
  min-height: 50vh;
}

@include xs {
  :root {
    .blog-posts.container {
      --grid-columns: 1;
    }
  }
}

@include breakpoint(620px) {
  :root {
    .blog-posts.container {
      --grid-columns: 2;
    }
  }
}

@include lg {
  :root {
    .blog-posts.container {
      --grid-columns: 3;
    }
  }
}

.categories-container {
  .categories-content {
    position: relative;
    display: flex;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 0.7em;
    &::after {
      content: "";
      position: absolute;
      bottom: 0.7em;
      left: 0;
      width: 100%;
      height: 1px;
      background: #bdc2ca;
    }
    span {
      position: relative;
      color: #bdc2ca;
      font-weight: 400;
      border-bottom: 1px solid #bdc2ca;
      padding: 0 1.3em 1em;
      white-space: nowrap;
      transition: 0.5s;
      cursor: pointer;
      z-index: 2;
      &.active,
      &:hover {
        color: #000;
        border-bottom-color: #000;
      }
    }
  }
}
