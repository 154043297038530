@use "sass:math";

/* --------------------------- layouts --------------------------- */

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 3em var(--constainer-padding);
}

.row,
.infinite-scroll-component {
  display: flex;
  flex-flow: row wrap;
  gap: var(--gap);
  &.reverse {
    flex-flow: row wrap-reverse;
  }
}

.col {
  box-sizing: border-box;
  flex-grow: 0;
  width: calc((100% / var(--grid-columns)) - (var(--gap) * ((var(--grid-columns) - 1) / var(--grid-columns))));
}

/* --------------------------- sections --------------------------- */

section {
  margin-top: 8em;
  &.subpages-heading {
    margin-top: 1em;
    h1,
    p {
      text-align: left;
      max-width: 700px;
      margin: 0 auto;
    }
    p {
      margin-top: 1.6em;
    }
    @include md {
      h1 {
        text-align: center;
      }
      .contact.container {
        h1 {
          text-align: left;
          margin: unset;
        }
      }
    }
    &.left-heading {
      h1,
      p {
        text-align: left;
        margin: unset;
      }
      p {
        margin-top: 1em;
      }
    }
  }
}

.light-background {
  background: #f7f9fc;
}
.dark-background {
  background: #1d1e24;
}

/* --------------------------- cards --------------------------- */

.card {
  padding: 2em;
  border-radius: 20px;
  /*&.not-complete-text {
    p {
      position: relative;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      &::after {
        content: "";
        position: absolute;
        bottom: 2px;
        right: 0;
        width: 50px;
        height: 16px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
      }
    }
  }*/
  &.card-border {
    border: 1px solid #e1e3e9;
  }
  &.card-shade {
    -webkit-box-shadow: 5px 6px 4px 0px rgba(226, 226, 226, 0.45);
    -moz-box-shadow: 5px 6px 4px 0px rgba(226, 226, 226, 0.45);
    box-shadow: 5px 6px 4px 0px rgba(226, 226, 226, 0.45);
  }
  &.card-dark {
    background: $clr-secondary-dark;
    &:nth-child(3) {
      svg {
        width: 25px;
      }
    }
    svg {
      width: 30px;
    }
  }
  .button-fill-shade {
    margin-top: 1.5em;
  }
}
.blog-post-card {
  transition: 0.2s;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
  h2 {
    font-size: clamp(1.7rem, 1.6500000000000001vw, 1.75rem);
  }
  .blog-image {
    position: relative;
    margin-bottom: 0.5em;
    img {
      width: 100%;
      height: 250px;
      border-radius: 10px;
      object-fit: cover;
    }
    .blog-banner {
      position: absolute;
      bottom: 5.8px;
      left: 0;
      justify-content: space-between;
      width: 100%;
      padding: 1em;
      color: #ffffff;
      background: rgba(255, 255, 255, 0.025);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(6.4px);
      -webkit-backdrop-filter: blur(6.4px);
      border-radius: 0 0 8.2px 8.2px;
      p {
        font-size: 0.9em;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}

/* --------------------------- slider --------------------------- */

.splide {
  padding: 0 0 2.5em;
  .splide__arrows {
    position: absolute;
    top: -40px;
    right: 0;
    display: none;
    width: 110px;
    .splide__arrow {
      svg {
        width: 25px;
        height: 25px;
        fill: $clr-secondary-dark;
      }
    }
  }
  .splide__track {
    padding: 2em;
  }
  .splide__slide {
    padding: 1.5em 2em 1em;
    h4 {
      margin-top: 1em;
    }
  }
  .splide__pagination {
    li {
      .splide__pagination__page {
        background: #e2e2e2;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        &:hover {
          background: #1554d0;
          opacity: 0.9;
        }
        &.is-active {
          background: #1554d0;
          opacity: 0.9;
        }
      }
    }
  }
}
@include md {
  .splide {
    .splide__arrows {
      display: unset;
    }
  }
}

/* --------------------------- form --------------------------- */

.form-container {
  display: grid;
  place-items: center;
  form {
    width: min(100%, 600px);
    background-color: $clr-secondary-dark;
    padding: 2em;
    border-radius: 15px;
    h3,
    input[type="text"],
    input[type="email"],
    textarea {
      color: $clr-white;
    }
    h3 {
      margin-bottom: 1.5em;
    }
    .input-container {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 1.5em;
      }
      label {
        position: relative;
        color: rgba(251, 251, 251, 0.64);
        font-size: 0.9em;
        &::after {
          content: "*";
          color: #e34248;
          margin-left: 0.4em;
        }
      }
      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 0.5em 0 1em;
        border-bottom: 1px solid rgba(251, 251, 251, 0.64);
        transition: 0.4s;
        resize: vertical;
        &:focus,
        &:not(:placeholder-shown) {
          border-color: $clr-white;
        }
      }
    }
    button {
      width: 100%;
      margin-top: 3.2em;
    }
  }
}

/* --------------------------- cooperation --------------------------- */

.coop-procedure {
  width: min(100%, 900px);
  .ring {
    display: grid;
    place-items: center;
    width: fit-content;
    padding: 0.1em 0.6em;
    margin: 0 auto 0.3em;
    border: 2px solid #ebedf4;
    border-radius: 50%;
    font-family: $ff-heading;
    font-weight: 600;
    font-size: 2em;
  }

  .coop-dots {
    position: relative;
    height: 100px;
    flex-grow: 3;
    .coop-dot {
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      display: block;
      width: 7px;
      height: 7px;
      background: #ebedf4;
      border-radius: 50%;
      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:nth-child(3) {
        right: 0;
      }
    }
  }
}
@include xs {
  :root {
    .coop-procedure.container {
      --grid-columns: 3;
    }
  }
  .coop-procedure.container {
    .row {
      .col {
        &:nth-child(4) {
          display: none;
        }
        .coop-procedure-step {
          display: grid;
          place-items: center;
        }
      }
    }
  }
}
@include lg {
  :root {
    .coop-procedure.container {
      --grid-columns: 7;
    }
  }
  .coop-procedure.container {
    .row {
      .col {
        &:nth-child(4) {
          display: unset;
        }
      }
    }
  }
}

/* --------------------------- FAQ --------------------------- */

.faq {
  width: min(100%, 600px);
  background: #f7f9fc;
  border-radius: 20px;
  .faq-card-container {
    margin-top: 2.6em;
    .faq-card {
      background: #fff;
      border-radius: 5px;
      padding: 1em;
      filter: drop-shadow(3px 5px 4px rgba(0, 0, 0, 0.04));
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      .faq-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
      }
      .collapse {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height 0.5s ease;
        &.show {
          height: auto;
        }
        p {
          margin-top: 1em;
        }
      }
    }
  }
}
@include lg {
  .faq {
    padding-inline: 2em;
  }
}

/* --------------------------- primary cta --------------------------- */

.primary-cta {
  &.dark-background {
    color: #fff;
    p {
      color: #ababae;
    }
  }
  text-align: center;
  padding: 2em 1em;
  border-radius: 20px;
  p {
    max-width: 560px;
    margin: 1.5em auto 1.3em;
  }
}

/* --------------------------- Cookie slat --------------------------- */

.cookie-jar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $clr-secondary-dark;
  border: 1px solid #363841;
  border-radius: 20px 20px 0 0;
  padding: 2em;
  z-index: 100;
  h4 {
    color: #fff;
    font-size: 1.3em;
  }
  p {
    font-size: .9em;
  }
  &-buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 2em;
    button {
      width: 100%;
      font-size: .9em;
    }
  }
}

@include breakpoint(395px) {
  .cookie-jar {
    &-buttons {
      button {
        width: unset;
      }
    }
  }
}

@include md {
  .cookie-jar {
    left: unset;
    right: 30px;
    bottom: 30px;
    border-radius: 20px;
    width: 400px;
  }
}