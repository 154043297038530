header {
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $clr-secondary-dark;
  border: 1px solid #363841;
  border-radius: 10px;
  width: min(calc(100% - var(--constainer-padding) * 2), 1300px);
  /*width: calc(100% - var(--constainer-padding) * 2);
  max-width: 1200px !important*/
  padding: 1em 2.2em;
  z-index: 1000;
  .nav-logo {
    svg {
      width: 25px;
    }
  }
  nav {
    display: none;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3em;
      li {
        &.active,
        &:hover {
          a {
            &::after {
              transform: scaleX(1);
              transform-origin: bottom left;
            }
          }
        }
        &:hover {
          a {
            opacity: .85;
          }
        }
        a {
          position: relative;
          display: inline-block;
          color: $clr-white;
          font-family: $ff-text;
          font-weight: 400;
          padding: 0 .2em;
          transition: .25s;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            transform: scaleX(0);
            width: 100%;
            height: 1px;
            background-color: #589cf0;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
        }
      }
    }
  }
  .nav-actions {
    .button-fill-light {
      display: none;
    }
    .hamburger-menu {
      display: grid;
      place-items: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: #363841;
      cursor: pointer;
      svg {
        margin-top: 0.3em;
        width: 16px;
        height: 13px;
      }
    }
  }
}

@include lg {
  header {
    nav {
      display: unset;
    }
    .nav-actions {
      .button-fill-light {
        display: unset;
      }
      .hamburger-menu {
        display: none;
      }
    }
  }
}

.breadcrumb {
  margin: 9em auto 0;
  width: 90%;
  ul {
    display: flex;
    justify-content: center;
    gap: 0.85em;
    li {
      display: flex;
      justify-content: center;
      a {
        display: flex;
        justify-content: center;
        span {
          color: #3a5fa9;
          transition: 0.3s;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            color: #4869ab;
          }
        }
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  width: min(100%, 18em);
  padding: 4em 2.1em 2.5em;
  border-radius: 20px;
  background: #fff;
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
  &.open {
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1002;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: grid;
    place-items: center;
    width: 45px;
    height: 45px;
    border: 1px solid #9d9ea1;
    border-radius: 50%;
    cursor: pointer;
    svg {
      margin-top: 0.3em;
      width: 50px;
    }
  }
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      a {
        font-size: 1.35em;
      }
    }
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  background: #0000005e;
  backdrop-filter: blur(4px);
  transition: 0.5s;
  z-index: -2;
  &.open {
    opacity: 1;
    z-index: 1001;
  }
}
