footer{
  position: relative;
  padding: 3em 1.18em 1em;
  background: $clr-secondary-dark;
  margin-top: 8em;
  .footer-top{
    justify-content: space-between;
    align-items: flex-start;
    gap: 12em;
    .footer-logo{
      svg{
        width: 10em;
      }
    }
    p{
      margin-top: 2em;
      color: rgba(251, 251, 251, 0.64);
      max-width: 500px;
    }
    .footer-links{
      align-items: flex-start;
      gap: 6em;
      h4{
        white-space: nowrap;
        color: #EBEDF0;
      }
      ul{
        margin-top: 1.3em;
        li{
          margin-bottom: .6em;
          a{
            color: rgba(251, 251, 251, 0.64);
            white-space: nowrap;
            transition: .5s;
            &:hover{
              color: rgba(251, 251, 251, 0.823);
            }
          }
        }
      } 
    }  
  }
  .footer-bottom{
    justify-content: space-between;
    border-top: 1px solid rgba(251, 251, 251, 0.64);
    padding-top: .7em;
    margin-top: 5em;
    p{
      color: #EBEDF0;
      a{
        color: #5CB1FF;
      }
    }
  }
}

@media screen and (max-width: 67.5rem){ // 1080px
  footer{
    .footer-top{
      flex-direction: column; 
      gap: 4em;
      p{
        display: none;
      }
      .footer-links{
        gap: 5em;
      }
    }  
  }
}

@media screen and (max-width: 21.4375rem){// 343px
  footer{
    .footer-top{
      .footer-links{
        gap: 2.3em;
      }
    }  
  }
}